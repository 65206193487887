@font-face {
    font-family: 'icomoon';
    src: url('font/icomoon.eot?aszi8r');
    src: url('font/icomoon.eot?aszi8r#iefix') format('embedded-opentype'),
        url('font/icomoon.ttf?aszi8r') format('truetype'),
        url('font/icomoon.woff?aszi8r') format('woff'),
        url('font/icomoon.svg?aszi8r#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-unarchive:before {
    content: '\1f5c1';
}
.icon-archive:before {
    content: '\1f5c4';
}
.icon-edit:before {
    content: '\270e';
}
.icon-add-tag:before {
    content: '\270f';
}
.icon-tag:before {
    content: '\1f5e9';
}
.icon-context-menu:before {
    content: '\22ee';
}
.icon-share:before {
    content: '\1f4e4';
}
.icon-menu:before {
    content: '\2261';
}
.icon-search:before {
    content: '\1f50d';
}
.icon-pin:before {
    content: '\1f588';
}
.icon-delete:before {
    content: '\1f5d1';
}
.icon-back:before {
    content: '\2b9c';
}
.icon-close:before {
    content: '\1f5d9';
}
.icon-sign-out:before {
    content: '\26bf';
}
.icon-new:before {
    content: '\1f5cb';
}

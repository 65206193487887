.host {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image {
    max-width: 60%;
    margin-bottom: var(--space-medium);
}

.button {
    background: var(--color-border);
    border: 0;
    color: var(--color-primary);
    font-size: var(--font-normal);
    padding: var(--space-small);
}

.button:hover,
.button:focus,
.button:active {
    background: var(--color-alt-background);
}

.footer {
    margin-top: var(--space-medium);
    font-size: var(--font-small);
}

.link,
.link:visited {
    text-decoration: none;
    color: var(--color-primary);
}

.loader,
.loader:before,
.loader:after {
    animation: load1 1s infinite ease-in-out;
    background: var(--color-primary);
    height: 4rem;
    width: 1rem;
}

.loader {
    animation-delay: -0.16s;
    color: var(--color-primary);
    margin: 0 auto;
    position: absolute;
    text-indent: -9999rem;
    top: 50%;
    transform: translateZ(0) translateY(-50%);
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.loader:before {
    animation-delay: -0.32s;
    left: -1.5rem;
}

.loader:after {
    left: 1.5rem;
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@import 'style.css';

:root {
    --animation-short: 0.15s;
    --animation-standard: 0.3s;
    --animation-long: 0.7s;
    --color-accent: #15c;
    --color-background: #fff;
    --color-alt-background: #f6f6f6;
    --color-border: #eee;
    --color-alt-border: #ddd;
    --color-primary: #555;
    --color-alt-primary: #444;
    --color-secondary: #999;
    --color-tertiary: #ccc;
    --color-accent-secondary: #9bf;
    --color-accent-secondary-rgb: 153, 187, 255;
    --font-small: 1.3rem;
    --font-normal: 1.6rem;
    --font-large: 2.4rem;
    --font-x-large: 3.2rem;
    --space-x-small: 0.8rem;
    --space-small: 1.6rem;
    --space-medium: 2.4rem;
    --space-large: 3.6rem;
    --space-x-large: 4.8rem;
}

@media (prefers-color-scheme: dark) {
    :root {
        --color-background: #333;
        --color-alt-background: #444;
        --color-border: #292929;
        --color-alt-border: #191919;
        --color-primary: #aaa;
        --color-alt-primary: #bbb;
        --color-secondary: #777;
        --color-tertiary: #ccc;
    }
}

html {
    font-size: 10px;
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--color-border);
    color: var(--color-primary);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    height: 100%;
    margin: 0;
    overflow: hidden;
}

main {
    background: var(--color-background);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    margin: auto;
    position: relative;
}

main > img {
    align-self: center;
    animation: pulse 1.5s infinite;
    max-width: 60%;
}

button,
input,
textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}
